import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "./api";

const initialState = {
  topicInfo: {
    title: "",
    questions: [],
  },
};

const newTopicSlice = createSlice({
  name: "newTopic",
  initialState,
  reducers: {
    // Setting course info (title and description)
    setTopicInfo: (state, action) => {
      state.topicInfo = { ...state.topicInfo, ...action.payload };
    },
    // Adding a question to a specific topic in a chapter
    addQuestionToTopic: (state, action) => {
      state.topicInfo.questions.push(action.payload);
    },

    // Remove a question from a topic
    removeQuestionFromTopic(state, action) {
      state.topicInfo.questions.splice(action.payload, 1);
    },
    // Clear the entire new course state
    clearNewTopic: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      apiSlice.endpoints.getTopic.matchFulfilled,
      (state, action) => {
        state.topicInfo = action.payload;
      },
    );
  },
});

export const {
  setTopicInfo,
  addQuestionToTopic,
  removeQuestionFromTopic,
  clearNewTopic,
} = newTopicSlice.actions;

export default newTopicSlice.reducer;
