import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./CourseLandingPage.css";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Image,
  Nav,
  Navbar,
  Row,
} from "react-bootstrap";
import {
  faCirclePlay,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useGetCourseQuery } from "../redux/api";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../redux/auth";

function CourseLandingPage() {
  const navigate = useNavigate();
  const currentUser = useSelector(selectCurrentUser);
  const { courseId } = useParams();
  // Fetch course data using RTK Query
  const {
    data: courseData,
    isLoading: courseLoading,
    error: courseError,
  } = useGetCourseQuery(courseId);

  // For toggling topic visibility
  const [isTopicsVisible, setIsTopicsVisible] = useState({});

  const toggleTopics = (chapterId) => {
    setIsTopicsVisible((prev) => ({
      ...prev,
      [chapterId]: !prev[chapterId],
    }));
  };

  if (courseLoading) {
    return <p>Loading...</p>; // Show loading state while data is being fetched
  }

  if (courseError) {
    return <p>Error loading course or chapters data!</p>; // Handle errors
  }

  return (
    <>
      <Navbar expand="lg" className="bg-light app-navbar" sticky="top">
        <Container>
          <Navbar.Brand href="#home">
            <img src="/logo.png" className="logo" alt="EdGuru logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="#home">Home</Nav.Link>
              <Nav.Link href="#link">Link</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container className="mb-5">
        <Row className="d-flex justify-content-center mb-5">
          <Col lg={9} sm={12}>
            {/* Course Data */}
            <img
              className="course-cover"
              src={courseData?.cover ?? "/course-cover.webp"}
              alt="course cover"
            />
            <h1>{courseData?.title}</h1>
            <p className="text-muted">with {courseData?.creator.name}</p>
            {currentUser?.id === courseData?.creator.id && (
              <button
                className="btn btn-info"
                onClick={() => navigate(`/app/course/${courseId}/edit`)}
              >
                Edit Course
              </button>
            )}
            <h3>About this course</h3>
            <p className="course-description">{courseData?.description}</p>
            <Card>
              <CardBody className="d-flex justify-content-between align-items-center">
                <strong>Enroll in course</strong>
                <Button>Enroll</Button>
              </CardBody>
            </Card>

            {/* Author Info */}
            <Card className="mt-4">
              <CardHeader>About the author</CardHeader>
              <CardBody>
                <div className="d-flex align-items-center mb-2">
                  <Image
                    className="profile-picture me-2"
                    src={"/profile-pic.jpeg"}
                    alt="author's face"
                    roundedCircle
                  />
                  <h5>{courseData?.creator.name}</h5>
                </div>
                <p>{courseData?.creator.bio}</p>
              </CardBody>
            </Card>

            {/* Course Curriculum */}
            <h3 className="mt-5 mb-3">Course curriculum</h3>
            <div className="curriculum">
              {courseData.chapters?.map((chapter) => (
                <div key={chapter.id} className="d-flex">
                  <div className="play-icon">
                    <FontAwesomeIcon size="lg" icon={faCirclePlay} />
                  </div>
                  <div className="chapter-details">
                    <h5>{chapter.title}</h5>
                    <p className="text-muted">
                      {chapter.description || "No description available."}
                    </p>
                    <Button
                      variant="link"
                      className="topics-button"
                      size="sm"
                      onClick={() => toggleTopics(chapter.id)}
                    >
                      {isTopicsVisible[chapter.id] ? (
                        <>
                          Hide topics{" "}
                          <FontAwesomeIcon size="sm" icon={faChevronUp} />
                        </>
                      ) : (
                        <>
                          Show topics{" "}
                          <FontAwesomeIcon size="sm" icon={faChevronDown} />
                        </>
                      )}
                    </Button>
                    {isTopicsVisible[chapter.id] && (
                      <ul className="topic-list">
                        {chapter.topics.map((topic) => (
                          <li key={topic.id} className="text-muted">
                            {topic.title}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              ))}
            </div>

            <Card>
              <CardBody className="d-flex justify-content-between align-items-center">
                <strong>Enroll in course</strong>
                <Button>Enroll</Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CourseLandingPage;
