import { useFormik } from "formik";
import Form from "react-bootstrap/Form";
import * as Yup from "yup";

const TopicForm = ({ initialValues, onValuesChange }) => {
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      title: Yup.string().required("Topic is required"),
      videoFile: Yup.mixed().required("A video file is required"),
    }),
  });

  const handleBlur = (e) => {
    formik.handleBlur(e);
    onValuesChange(formik.values);
  };

  return (
    <Form onBlur={handleBlur} className="form-container section">
      <div className="d-flex justify-content-start w-100">
        <h4>Topic Overview</h4>
      </div>
      <Form.Group>
        <Form.Label>Topic for Video</Form.Label>
        <Form.Control
          type="text"
          name="title"
          placeholder="Topic title"
          value={formik.values.title}
          onChange={formik.handleChange}
          isInvalid={!!formik.errors.title && formik.touched.title}
        />
        <Form.Control.Feedback type="invalid">
          {formik.errors.title}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="formFile">
        <Form.Label>Video File</Form.Label>
        <Form.Control
          type="file"
          name="videoFile"
          onChange={(event) => {
            formik.setFieldValue("videoFile", event.currentTarget.files[0]);
          }}
          isInvalid={!!formik.errors.videoFile && formik.touched.videoFile}
        />
        <Form.Control.Feedback type="invalid">
          {formik.errors.videoFile}
        </Form.Control.Feedback>
      </Form.Group>
    </Form>
  );
};

export default TopicForm;
