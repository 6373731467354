import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import { logout } from "../redux/auth";
import { persistor } from "../store";

import "./AuthenticatedLayout.css";
import Logo from "../shared/components/Logo";

function AuthenticatedLayout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Select authentication state from Redux store
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  // Check authentication on component mount
  useEffect(() => {
    if (!isAuthenticated) {
      // Redirect to login if not authenticated
      navigate("/signin");
    }
  }, [isAuthenticated, navigate]);

  // Handle sign-out
  const handleSignOut = async () => {
    try {
      dispatch(logout());
      persistor.purge();
      navigate("/signin"); // Redirect to login page after sign-out
    } catch (err) {
      console.error("Error during sign out:", err);
    }
  };

  return (
    <>
      <Navbar expand="lg" className="bg-white app-navbar" sticky="top">
        <Container>
          <Navbar.Brand href="/app/dashboard">
            <Logo />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="d-flex align-items-center">
              <Nav.Link href="#home">Dashboard</Nav.Link>
              <NavDropdown id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Settings</NavDropdown.Item>
                <NavDropdown.Item onClick={handleSignOut}>
                  Sign out
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container className="outlet-container">
        <Outlet />
      </Container>
    </>
  );
}

export default AuthenticatedLayout;
