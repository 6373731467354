import { Breadcrumb, Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import ChapterInfoForm from "../ChapterForm";
import "../Chapter.css";
import { useCreateChapterMutation } from "../../../redux/api";
import { clearNewChapter, setChapterInfo } from "../../../redux/newChapter";
import toast from "react-hot-toast";

const NewChapter = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [createChapter, { isLoading }] = useCreateChapterMutation();
  const { chapterInfo } = useSelector((state) => state.newChapter);
  let { courseId } = useParams();

  const handleSubmitChapter = async () => {
    try {
      const createdChapter = await createChapter({
        course: courseId,
        ...chapterInfo,
      }).unwrap();
      dispatch(clearNewChapter());
      navigate(`/app/course/${courseId}/chapter/${createdChapter.id}/edit`);
      toast.success("Chapter added successfully!");
    } catch (err) {
      console.error("Failed to create chapter:", err);
      toast.error("Something went wrong.");
    }
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => navigate(`/app/dashboard`)}>
          Dashboard
        </Breadcrumb.Item>
        <Breadcrumb.Item onClick={() => navigate(`/app/newcourse`)}>
          Course
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Chapter</Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col className="d-flex flex-column justify-content-center align-items-center header">
          <h1 className="form-heading">New Chapter</h1>
          <p className="text-muted">
            Chapter is one block of related topics, let's add some content to
            your chapter
          </p>
        </Col>
      </Row>
      <Row className="w-100 mb-5">
        <Col
          sm={12}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          <ChapterInfoForm
            initialValues={{
              title: chapterInfo.title ?? "",
              description: chapterInfo.description ?? "",
            }}
            onValuesChange={(updatedValues) => {
              dispatch(setChapterInfo(updatedValues));
            }}
          />

          <div className="form-container d-flex justify-content-between mt-3">
            <Button
              variant="link"
              as={Link}
              // onClick={() => {
              //   dispatch(clearNewChapter());
              // }}
              to={`/app/course/${courseId}/edit`}
            >
              Back
            </Button>
            <Button
              type="button"
              onClick={handleSubmitChapter}
              disabled={isLoading}
            >
              Save
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default NewChapter;
