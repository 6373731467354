import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "./api";

const initialState = {
  chapterInfo: {
    title: "",
    description: "",
    topics: [],
  },
};

const newCourseSlice = createSlice({
  name: "newChapter",
  initialState,
  reducers: {
    // Setting course info (title and description)
    setChapterInfo: (state, action) => {
      state.chapterInfo = { ...state.chapterInfo, ...action.payload };
    },
    // Clear the entire new course state
    clearNewChapter: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      apiSlice.endpoints.getChapter.matchFulfilled,
      (state, action) => {
        state.chapterInfo = action.payload;
      },
    );
  },
});

export const { setChapterInfo, clearNewChapter } = newCourseSlice.actions;

export default newCourseSlice.reducer;
