import "./ViewCourse.css";
import {
  faLightbulb,
  faRectangleList,
  faMessage,
} from "@fortawesome/free-solid-svg-icons";
import {
  faPaperPlane,
  faRectangleList as faRectangleListOff,
  faMessage as faMessageOff,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import {
  Accordion,
  Button,
  Col,
  Container,
  Form,
  FormControl,
  Navbar,
  Row,
} from "react-bootstrap";

function Course() {
  const [showContents, setShowContents] = useState(true);
  const [showNerdi, setShowNerdi] = useState(true);

  return (
    <>
      <Navbar expand="lg" className="bg-light app-navbar" sticky="top">
        <Container fluid>
          <Navbar.Brand href="#home">
            <h3 className="logo-text ms-3 mt-2 text-primary">Coursable</h3>
          </Navbar.Brand>
          {/* <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="#home">Home</Nav.Link>
              <Nav.Link href="#link">Link</Nav.Link>
            </Nav>
          </Navbar.Collapse> */}
        </Container>
      </Navbar>
      <Container fluid className="course-container">
        <Row>
          {showContents && (
            <Col lg={2} className="curriculum-column">
              <Accordion className="chapters-container">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <strong>Introduction to yoga</strong>
                  </Accordion.Header>
                  <Accordion.Body className="topic-items">
                    <div>Intro to yoga</div>
                    <div>Yogatarian concepts</div>
                    <div>Final yoga module</div>
                    <div>Porta ac consectetur ac</div>
                    <div>Vestibulum at eros</div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <strong>Chapter 2: Getting Failed</strong>
                  </Accordion.Header>
                  <Accordion.Body className="topic-items">
                    <div>Intro to yoga</div>
                    <div>Yogatarian concepts</div>
                    <div>Final yoga module</div>
                    <div>Porta ac consectetur ac</div>
                    <div>Vestibulum at eros</div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          )}
          <Col lg={6} className="video-column">
            <div className="d-flex justify-content-between align-items-center">
              <h6>Introduction to yoga from home</h6>
              <div className="layout-actions mb-1">
                <Button
                  variant="light"
                  size="sm"
                  className="me-2"
                  onClick={() => setShowContents(!showContents)}
                >
                  {showContents ? (
                    <FontAwesomeIcon icon={faRectangleList} />
                  ) : (
                    <FontAwesomeIcon icon={faRectangleListOff} />
                  )}
                </Button>
                <Button
                  variant="light"
                  size="sm"
                  onClick={() => {
                    setShowNerdi(!showNerdi);
                  }}
                >
                  {showNerdi ? (
                    <FontAwesomeIcon icon={faMessage} />
                  ) : (
                    <FontAwesomeIcon icon={faMessageOff} />
                  )}
                </Button>
              </div>
            </div>
            <video controls className="video">
              <source
                src="https://www.youtube.com/watch?v=U5kU-w9ii0Y&pp=ygUJYWxpYWJkYWFs"
                type="video/mp4"
              />
              <source
                src="https://www.youtube.com/watch?v=U5kU-w9ii0Y&pp=ygUJYWxpYWJkYWFs"
                type="video/ogg"
              />
              Your browser does not support the video tag.
            </video>
            <div className="description-container">
              <h5 className="mt-1">Introduction to yoga modules</h5>
              <h5 className="mt-4">Overview</h5>
              <p className="chapter-description">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Vestibulum dolor ligula, suscipit in risus mollis, imperdiet
                vestibulum enim. Praesent auctor fringilla egestas. Morbi tempor
                purus vel velit eleifend feugiat. Vestibulum placerat ante in
                ante ullamcorper vehicula. Donec gravida porttitor mi, eu
                eleifend erat ultricies vitae. Aliquam nibh diam, sagittis ut
                semper eu, ullamcorper vitae sapien. Donec ac fermentum orci, a
                condimentum nibh. Vestibulum ac orci est. In feugiat a justo a
                accumsan. Morbi eget risus venenatis, dictum dui eget, luctus
                magna. Donec fermentum tempus nulla non lobortis. Lorem ipsum
                dolor sit amet, consectetur adipiscing elit. Vestibulum dolor
                ligula, suscipit in risus mollis, imperdiet vestibulum enim.
                Praesent auctor fringilla egestas. Morbi tempor purus vel velit
                eleifend feugiat. Vestibulum placerat ante in ante ullamcorper
                vehicula. Donec gravida porttitor mi, eu eleifend erat ultricies
                vitae. Aliquam nibh diam, sagittis ut semper eu, ullamcorper
                vitae sapien. Donec ac fermentum orci, a condimentum nibh.
                Vestibulum ac orci est. In feugiat a justo a accumsan. Morbi
                eget risus venenatis, dictum dui eget, luctus magna. Donec
                fermentum tempus nulla non lobortis.
              </p>
            </div>
          </Col>
          {showNerdi && (
            <Col lg={4} className="ask-column">
              <div className="ask-heading">
                <h5>
                  <FontAwesomeIcon icon={faLightbulb} /> Have questions? Just
                  Ask.
                </h5>
              </div>
              <div className="ask-column-layout">
                <div className="w-100 messages-list">
                  <div className="chat-text-container">
                    <div>
                      <img
                        className="nerdi-avatar"
                        src="/nerdi.png"
                        alt="bot-logo"
                      />
                    </div>
                    <div className="chat-text">
                      <small className="text-muted">
                        <strong className="name">Nerdi</strong>
                      </small>
                      <div className="message">
                        Hey 👋 <br />
                        Nice to meet you. I am Nerdi
                        <br /> M Siddiqui's assistant. Feel free to ask me any
                        question you might have during your course here.
                        <br />
                        As if you were asking it live.
                      </div>
                    </div>
                  </div>
                  <div className="chat-text-container">
                    <div>
                      <img
                        className="nerdi-avatar"
                        src="/nerdi.png"
                        alt="bot-logo"
                      />
                    </div>
                    <div className="chat-text">
                      <small className="text-muted">
                        <strong className="name">Nerdi</strong>
                      </small>
                      <div className="message">
                        Hey 👋 <br />
                        Nice to meet you. I am Nerdi
                        <br /> M Siddiqui's assistant. Feel free to ask me any
                        question you might have during your course here.
                        <br />
                        As if you were asking it live.
                      </div>
                    </div>
                  </div>
                  <div className="chat-text-container">
                    <div>
                      <img
                        className="nerdi-avatar"
                        src="/nerdi.png"
                        alt="bot-logo"
                      />
                    </div>
                    <div className="chat-text">
                      <small className="text-muted">
                        <strong className="name">Nerdi</strong>
                      </small>
                      <div className="message">
                        Hey 👋 <br />
                        Nice to meet you. I am Nerdi
                        <br /> M Siddiqui's assistant. Feel free to ask me any
                        question you might have during your course here.
                        <br />
                        As if you were asking it live.
                      </div>
                    </div>
                  </div>
                  <div className="chat-text-container">
                    <div>
                      <img
                        className="nerdi-avatar"
                        src="/nerdi.png"
                        alt="bot-logo"
                      />
                    </div>
                    <div className="chat-text">
                      <small className="text-muted">
                        <strong className="name">Nerdi</strong>
                      </small>
                      <div className="message">
                        Hey 👋 <br />
                        Nice to meet you. I am Nerdi
                        <br /> M Siddiqui's assistant. Feel free to ask me any
                        question you might have during your course here.
                        <br />
                        As if you were asking it live.
                      </div>
                    </div>
                  </div>
                  <div className="chat-text-container">
                    <div>
                      <img
                        className="nerdi-avatar"
                        src="/nerdi.png"
                        alt="bot-logo"
                      />
                    </div>
                    <div className="chat-text">
                      <small className="text-muted">
                        <strong className="name">Nerdi</strong>
                      </small>
                      <div className="message">
                        Hey 👋 <br />
                        Nice to meet you. I am Nerdi
                        <br /> M Siddiqui's assistant. Feel free to ask me any
                        question you might have during your course here.
                        <br />
                        As if you were asking it live.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-100">
                <Form.Group className="ask-text-box">
                  <FormControl
                    type="text"
                    as="textarea"
                    placeholder="Ask a question, as if you were taking this course live"
                  />
                  <Button variant="dark ms-1">
                    <FontAwesomeIcon icon={faPaperPlane} />
                  </Button>
                </Form.Group>
              </div>
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
}

export default Course;
