import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Breadcrumb, Col, Row, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  useGetChapterQuery,
  useUpdateChapterMutation,
} from "../../../redux/api";
import { clearNewChapter, setChapterInfo } from "../../../redux/newChapter";
import "../Chapter.css";
import ChapterInfoForm from "../ChapterForm";
import TopicItem from "../TopicItem";
import toast from "react-hot-toast";

const NewChapter = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { courseId, chapterId } = useParams();
  const [updateChapter, { isLoading: isUpdateChapterLoading }] =
    useUpdateChapterMutation();
  const { data: chapter, isLoading: isGetChapterLoading } =
    useGetChapterQuery(chapterId);
  const { chapterInfo } = useSelector((state) => state.newChapter);

  // const dispatch = useDispatch();

  const handleAddNewTopic = () => {
    navigate(`/app/course/${courseId}/chapter/${chapterId}/topic/add`); // Navigate to add topic screen
  };

  const handleSubmitChapter = async () => {
    try {
      await updateChapter(chapterInfo).unwrap();
      dispatch(clearNewChapter());
      toast.success("Chapter updated successfully!");
    } catch (err) {
      console.error("Failed to update chapter:", err);
      toast.error("Something went wrong.");
    }
  };

  if (isGetChapterLoading) return <Spinner />;

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => navigate(`/app/dashboard`)}>
          Dashboard
        </Breadcrumb.Item>
        <Breadcrumb.Item
          onClick={() => navigate(`/app/course/${courseId}/edit`)}
        >
          Course
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Chapter</Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col className="d-flex flex-column justify-content-center align-items-center header">
          <h1 className="form-heading">Edit Chapter</h1>
          <p className="text-muted">
            Chapter is one block of related topics, let's add some content to
            your chapter
          </p>
        </Col>
      </Row>
      <Row className="w-100 mb-5">
        <Col
          sm={12}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          <ChapterInfoForm
            initialValues={{
              title: chapter?.title ?? "",
              description: chapter?.description ?? "",
            }}
            onValuesChange={(updatedValues) => {
              dispatch(setChapterInfo(updatedValues));
            }}
          />
          <div className="form-container section">
            <h4>Topics</h4>
            {chapterInfo?.topics.length > 0
              ? chapterInfo.topics.map((topic, index) => (
                  <TopicItem
                    key={index}
                    topic={`Topic ${index + 1}: ${topic.title}`}
                    questionsCount={topic.questions?.length ?? 0}
                    onClick={() => {
                      navigate(
                        `/app/course/${courseId}/chapter/${chapterId}/topic/${topic.id}/edit`,
                      );
                    }}
                  />
                ))
              : null}
            <div className="add-new-chapter" onClick={handleAddNewTopic}>
              <FontAwesomeIcon className="m-2" icon={faPlus} />
              Add a {chapterInfo?.topics?.length > 0 ? "new" : ""} topic
            </div>
          </div>

          <div className="form-container d-flex justify-content-between mt-3">
            <Button
              variant="link"
              as={Link}
              // onClick={() => {
              //   dispatch(clearNewChapter());
              // }}
              to={`/app/course/${courseId}/edit`}
            >
              Back
            </Button>
            <Button
              type="button"
              onClick={handleSubmitChapter}
              disabled={isUpdateChapterLoading}
            >
              Save
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default NewChapter;
