import {
  Accordion,
  Breadcrumb,
  Button,
  Col,
  Row,
  Spinner,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import QuestionForm from "../QuestionForm";
import TopicForm from "../TopicForm"; // Renamed from VideoForm
import "../Topic.css";
import {
  addQuestionToTopic,
  clearNewTopic,
  setTopicInfo,
} from "../../../redux/newTopic";
import { useGetTopicQuery, useUpdateTopicMutation } from "../../../redux/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import toast from "react-hot-toast";

const EditTopic = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { courseId, chapterId, topicId } = useParams();
  const [updateTopic, { isLoading: isUpdateTopicLoading }] =
    useUpdateTopicMutation();
  const { data: topic, isLoading: isGetTopicLoading } =
    useGetTopicQuery(topicId);
  const { topicInfo } = useSelector((state) => state.newTopic);
  const [videoFile, setVideoFile] = useState();

  const handleSubmitTopic = async () => {
    try {
      const formData = new FormData();
      formData.append("chapter", chapterId);
      formData.append("title", topicInfo.title);
      if (videoFile) {
        formData.append("video_file", videoFile);
      }

      topicInfo.questions?.forEach((question, questionIndex) => {
        formData.append(`questions[${questionIndex}].topic`, topicId);
        formData.append(
          `questions[${questionIndex}].question_text`,
          question.question_text,
        );
        question.answers.forEach((answer, answerIndex) => {
          formData.append(
            `questions[${questionIndex}].answers[${answerIndex}].answer_text`,
            answer.answer_text,
          );
          formData.append(
            `questions[${questionIndex}].answers[${answerIndex}].is_correct`,
            answer.is_correct,
          );
        });
      });
      await updateTopic({ topicId, topicData: formData }).unwrap();
      dispatch(clearNewTopic());
      toast.success("Topic updated successfully");
    } catch (err) {
      console.error("Failed to update topic:", err);
      toast.error("Something went wrong.");
    }
  };

  if (isGetTopicLoading) return <Spinner />;

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => navigate(`/app/dashboard`)}>
          Dashboard
        </Breadcrumb.Item>
        <Breadcrumb.Item onClick={() => navigate(`/app/newcourse/`)}>
          Course
        </Breadcrumb.Item>
        <Breadcrumb.Item
          onClick={() =>
            navigate(`/app/course/${courseId}/chapter/${chapterId}/edit`)
          }
        >
          Chapter
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Topic</Breadcrumb.Item>
      </Breadcrumb>

      <Row>
        <Col className="d-flex flex-column justify-content-center align-items-center header">
          <h1 className="form-heading">Edit Topic</h1>
          <p className="text-muted">
            Its time to add your video and quiz questions.
          </p>
        </Col>
      </Row>
      <Row className="w-100 mb-5">
        <Col
          sm={12}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          <TopicForm
            initialValues={{
              title: topic?.title ?? "",
              videoFile: null,
            }}
            onValuesChange={(updatedValues) => {
              dispatch(setTopicInfo({ title: updatedValues.title }));
              setVideoFile(updatedValues.videoFile);
            }}
          />
          <div sm={12} className="form-container section">
            <h4>Questions</h4>
            {topicInfo?.questions.length > 0 ? (
              <Accordion>
                {topicInfo.questions.map((question, questionIndex) => (
                  <Accordion.Item
                    key={questionIndex}
                    eventKey={questionIndex.toString()}
                  >
                    <Accordion.Header>
                      {question.question_text}
                    </Accordion.Header>
                    <Accordion.Body>
                      <ul>
                        {question.answers.map((answer, answerIndex) => (
                          <li key={answerIndex}>
                            {answer.answer_text}{" "}
                            {answer.is_correct ? "(Correct)" : ""}
                          </li>
                        ))}
                      </ul>
                      <Button
                        variant="danger"
                        // onClick={() => handleRemoveQuestion(questionIndex)}
                      >
                        <FontAwesomeIcon icon={faTrash} /> Remove Question
                      </Button>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            ) : null}
            <QuestionForm
              onAdd={(values) => {
                dispatch(addQuestionToTopic(values));
              }}
            />
          </div>
          <div className="form-container d-flex justify-content-between mt-3">
            <Button
              variant="link"
              as={Link}
              // onClick={() => {
              //   dispatch(clearNewTopic());
              // }}
              to={`/app/course/${courseId}/chapter/${chapterId}/edit`}
            >
              Back
            </Button>
            <Button
              type="button"
              onClick={handleSubmitTopic}
              disabled={isUpdateTopicLoading}
            >
              Save Topic
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default EditTopic;
