import EditCourse from "./edit/EditCourse";
import NewCourse from "./new/NewCourse";
import ViewCourse from "./view/ViewCourse";

const Course = ({ mode }) => {
  if (mode === "add") {
    return <NewCourse />;
  }

  if (mode === "edit") {
    return <EditCourse />;
  }

  if (mode === "view") {
    return <ViewCourse />;
  }
};

export default Course;
