import { Button } from "react-bootstrap";
import Card from "react-bootstrap/Card";

function Course({ title, chapters, students, onClick }) {
  return (
    <Card onClick={onClick} className="h-100">
      <Card.Img variant="top" src="/course.jpeg" />
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text className="text-muted">
          {chapters} Chapters &bull; {students} Students
        </Card.Text>
      </Card.Body>
      <Card.Footer className="d-flex justify-content-end bg-white">
        <Button className="unset-button-width me-1" variant="light" size="sm">
          View
        </Button>
        <Button className="unset-button-width" variant="light" size="sm">
          Edit
        </Button>
      </Card.Footer>
    </Card>
  );
}

export default Course;
