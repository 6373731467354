import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldArray, Formik } from "formik";
import { useState } from "react";
import { Button, Card } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import * as Yup from "yup";

const QuestionForm = ({ onAdd }) => {
  const [showQuestionForm, setShowQuestionForm] = useState(false);

  const initialValues = {
    question_text: "",
    answers: [
      // { answer_text: "Dummy answer", is_correct: false, isEditting: false },
    ],
  };
  const validationSchema = Yup.object({
    question_text: Yup.string().required("Please type your question"),
    answers: Yup.array().of(
      Yup.object({
        answer_text: Yup.string().required("Answer is required"),
        is_correct: Yup.boolean(),
        isEditting: Yup.boolean(),
      }),
    ),
  });
  const onSubmit = (values, { resetForm }) => {
    onAdd(values);
    resetForm();
    setShowQuestionForm(false);
  };

  const handleCancelQuestion = (formik) => {
    formik.resetForm();
    setShowQuestionForm(false);
  };

  return (
    <>
      {!showQuestionForm && (
        <div
          className={`add-new-question`}
          onClick={() => {
            setShowQuestionForm(true);
          }}
        >
          <FontAwesomeIcon className="m-2" icon={faPlus} />
          Add a new question
        </div>
      )}

      {showQuestionForm && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(formik) => (
            <Form className="section form-container" as={Card}>
              <Card.Body>
                <Form.Group>
                  <Form.Control
                    aria-label="Question"
                    as="textarea"
                    rows={2}
                    placeholder="Type your question..."
                    name="question_text"
                    value={formik.values.question_text}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isInvalid={
                      !!formik.errors.question_text &&
                      formik.touched.question_text
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.question_text}
                  </Form.Control.Feedback>
                </Form.Group>

                <FieldArray
                  name="answers"
                  render={(arrayHelpers) => (
                    <div className="answers-list mt-2">
                      {formik.values.answers.length > 0
                        ? formik.values.answers.map((answer, answerIndex) => (
                            <div className="answers-list" key={answerIndex}>
                              {!answer.isEditting && (
                                <div className="answer-option">
                                  <div className="mt-2 w-100">
                                    <small className="text-muted">
                                      <strong> Answer {answerIndex + 1}</strong>
                                    </small>
                                    <p className="answer-text">
                                      {answer.answer_text}
                                    </p>
                                    <div
                                      className={`d-flex align-items-center justify-content-${answer.is_correct ? "between" : "end"}`}
                                    >
                                      {answer.is_correct && (
                                        <small className="text-muted">
                                          Marked as correct
                                        </small>
                                      )}
                                      <Button variant="link">
                                        Delete answer
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {answer.isEditting && (
                                <div className="answer-option">
                                  <div className="w-100">
                                    <small className="text-muted d-inline-block mt-2">
                                      <strong> Answer {answerIndex + 1}</strong>
                                    </small>
                                    <Form.Group>
                                      <Form.Control
                                        className="mt-2"
                                        type="text"
                                        as="textarea"
                                        rows={2}
                                        value={answer.answer_text}
                                        name={`answers.${answerIndex}.answer_text`}
                                        onChange={formik.handleChange}
                                      />
                                    </Form.Group>
                                    <div className="d-flex justify-content-between align-items-center mt-2">
                                      <Form.Check
                                        className="mt-1"
                                        type="checkbox"
                                        label={`Mark as correct`}
                                        name={`answers.${answerIndex}.is_correct`}
                                        onChange={formik.handleChange}
                                        checked={answer.is_correct}
                                      />
                                      <div>
                                        <Button
                                          size="sm"
                                          className="me-2"
                                          variant="light"
                                          onClick={() => {
                                            formik.setFieldValue(
                                              `answers.${answerIndex}.isEditting`,
                                              false,
                                            );
                                          }}
                                        >
                                          Cancel
                                        </Button>
                                        <Button
                                          size="sm"
                                          variant="primary"
                                          onClick={() => {
                                            formik.setFieldValue(
                                              `answers.${answerIndex}.isEditting`,
                                              false,
                                            );
                                          }}
                                        >
                                          Save
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          ))
                        : null}
                      <div
                        className="add-answer"
                        onClick={() =>
                          arrayHelpers.push({
                            answer_text: "",
                            is_correct: false,
                            isEditting: true,
                          })
                        }
                      >
                        <FontAwesomeIcon
                          icon={faPlus}
                          className="add-answer-icon"
                          size="sm"
                        />
                        <span>Add answer</span>
                      </div>
                    </div>
                  )}
                />

                <div className="d-flex justify-content-end mt-3">
                  <Button
                    variant="light"
                    className="me-2"
                    size="sm"
                    onClick={() => handleCancelQuestion(formik)}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="sm"
                    variant="primary"
                    type="submit"
                    disabled={
                      !formik.values.question_text ||
                      formik.errors.question_text ||
                      formik.values.answers.some(
                        (answer) => !answer.answer_text,
                      ) ||
                      !formik.values.answers.some((answer) => answer.is_correct)
                    }
                    onClick={formik.handleSubmit}
                  >
                    Save question
                  </Button>
                </div>
              </Card.Body>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default QuestionForm;
