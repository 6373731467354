import { Breadcrumb, Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useCreateCourseMutation } from "../../../redux/api";
import { clearNewCourse, setCourseInfo } from "../../../redux/newCourse";
import "../Course.css";
import CourseForm from "../CourseForm";
import toast from "react-hot-toast";

const NewCourse = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [createCourse, { isLoading }] = useCreateCourseMutation();
  const { courseInfo } = useSelector((state) => state.newCourse);

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("title", courseInfo?.title);
      formData.append("description", courseInfo?.description);
      const createdCourse = await createCourse(formData).unwrap();
      dispatch(clearNewCourse());
      navigate(`/app/course/${createdCourse.id}/edit`);
      toast.success("Course saved successfully!");
    } catch (err) {
      console.error("Failed to create course:", err);
      toast.error("Something went wrong.");
    }
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => navigate("/app/dashboard")}>
          Courses
        </Breadcrumb.Item>
        <Breadcrumb.Item active>New Course</Breadcrumb.Item>
      </Breadcrumb>

      <Row>
        <Col className="d-flex flex-column justify-content-center align-items-center header">
          <h1 className="form-heading">New Course</h1>
          <p className="text-muted">
            We are creating a new course. You can save your progress to continue
            later.
          </p>
        </Col>
      </Row>

      <Row className="w-100 mb-5">
        <Col
          sm={12}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          <CourseForm
            initialValues={{
              title: courseInfo.title ?? "",
              description: courseInfo.description ?? "",
            }}
            onValuesChange={(updatedValues) => {
              dispatch(setCourseInfo(updatedValues));
            }}
          />

          <div className="d-flex form-container justify-content-between section">
            <Button
              variant="link"
              // onClick={() => {
              //   dispatch(clearNewCourse());
              // }}
              as={Link}
              to="/app/dashboard"
            >
              Back
            </Button>
            <Button
              variant="primary"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              Save
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default NewCourse;
