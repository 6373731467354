import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import React from "react";
import {
  createBrowserRouter,
  redirect,
  RouterProvider,
} from "react-router-dom";

import { Provider } from "react-redux";
import { Toaster } from "react-hot-toast";

import { persistor, store } from "./store";

import AuthenticatedLayout from "./authenticated/AuthenticatedLayout";
import Settings from "./settings/Settings";
import SignIn from "./signin/SignIn";
import SignUp from "./signup/SignUp";
import Dashboard from "./authenticated/dashboard/Dashboard";
import { PersistGate } from "redux-persist/integration/react";
import Course from "./authenticated/course/Course";
import Chapter from "./authenticated/chapter/Chapter";
import Topic from "./authenticated/topic/Topic";
import CourseLandingPage from "./course/CourseLandingPage";

const router = createBrowserRouter([
  {
    path: "/",
    loader: () => {
      const state = store.getState();
      if (state.auth.isAuthenticated) {
        return redirect("/app/dashboard");
      } else {
        return redirect("/signin");
      }
    },
  },
  {
    path: "/signup",
    element: <SignUp />,
  },
  {
    path: "/signin",
    element: <SignIn />,
  },
  {
    path: "/app",
    element: <AuthenticatedLayout />,
    children: [
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "course/add",
        element: <Course mode="add" />,
      },
      {
        path: "course/:courseId/edit",
        element: <Course mode="edit" />,
      },
      {
        path: "course/:courseId/chapter/add",
        element: <Chapter mode="add" />,
      },
      {
        path: "course/:courseId/chapter/:chapterId/edit",
        element: <Chapter mode="edit" />,
      },
      {
        path: "course/:courseId/chapter/:chapterId/topic/add",
        element: <Topic mode="add" />,
      },
      {
        path: "course/:courseId/chapter/:chapterId/topic/:topicId/edit",
        element: <Topic mode="edit" />,
      },
    ],
  },
  {
    path: "course/:courseId/view",
    element: <Course mode="view" />,
  },
  {
    path: "/settings",
    element: <Settings />,
  },
  {
    path: "/course/:courseId",
    element: <CourseLandingPage />,
  },
]);

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <RouterProvider router={router}></RouterProvider>
        <Toaster />
      </PersistGate>
    </Provider>
  );
}

export default App;
