import { Card, CardBody } from "react-bootstrap";

function Tile({ number, caption }) {
  return (
    <Card className="tile">
      <CardBody>
        <Card.Title className="d-flex justify-content-center">
          <strong>{number}</strong>
        </Card.Title>
        <Card.Text className="text-muted">{caption}</Card.Text>
      </CardBody>
    </Card>
  );
}

export default Tile;
