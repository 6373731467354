import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "./redux/auth";
import newCourseReducer from "./redux/newCourse";
import newChapterReducer from "./redux/newChapter";
import newTopicReducer from "./redux/newTopic";
import { apiSlice } from "./redux/api";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

// Persist config for redux slices
const persistConfig = {
  key: "root", // the key for localStorage
  storage, // localStorage as default storage
  whitelist: ["auth", "newCourse", "newChapter"], // we are persisting only auth and chapters
};

// Combine reducers for persisting state
const rootReducer = combineReducers({
  auth: authReducer,
  newCourse: newCourseReducer,
  newChapter: newChapterReducer,
  newTopic: newTopicReducer,
  [apiSlice.reducerPath]: apiSlice.reducer, // api slice
});

// Create persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the store
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
      },
    }).concat(apiSlice.middleware),
});

// Persistor for persisting the store
export const persistor = persistStore(store);
