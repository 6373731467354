import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Breadcrumb, Button, Col, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useGetCourseQuery, useUpdateCourseMutation } from "../../../redux/api";
import { clearNewCourse, setCourseInfo } from "../../../redux/newCourse";
import ChapterItem from "../ChapterItem";
import "../Course.css";
import CourseForm from "../CourseForm";
import toast from "react-hot-toast";

const EditCourse = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { courseId } = useParams();
  const { data: course, isLoading: isGetCourseLoading } =
    useGetCourseQuery(courseId);

  const [updateCourse, { isLoading: isUpdateCourseLoading }] =
    useUpdateCourseMutation();
  const { courseInfo } = useSelector((state) => state.newCourse);

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("title", courseInfo?.title);
      formData.append("description", courseInfo?.description);
      await updateCourse({ courseId, courseData: formData }).unwrap();
      dispatch(clearNewCourse());
      toast.success("Course updated successfully!", {
        autoClose: false,
      });
    } catch (err) {
      console.error("Failed to update course:", err);
      toast.error("Something went wrong.");
    }
  };

  const handleAddNewChapter = () => {
    navigate(`/app/course/${courseId}/chapter/add`);
  };

  if (isGetCourseLoading) {
    return <Spinner />;
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => navigate("/app/dashboard")}>
          Courses
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Course</Breadcrumb.Item>
      </Breadcrumb>

      <Row>
        <Col className="d-flex flex-column justify-content-center align-items-center header">
          <h1 className="form-heading">Edit Course</h1>
          <p className="text-muted">
            We are editting your draft course. You can save your progress to
            continue later.
          </p>
        </Col>
      </Row>

      <Row className="w-100 mb-5">
        <Col
          sm={12}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          <CourseForm
            initialValues={{
              title: course.title ?? "",
              description: course.description ?? "",
            }}
            onValuesChange={(updatedValues) => {
              dispatch(setCourseInfo(updatedValues));
            }}
          />
          <div className="section form-container">
            <h4>Chapters</h4>
            {courseInfo.chapters.length > 0
              ? courseInfo.chapters.map((chapter, index) => (
                  <ChapterItem
                    key={index}
                    chapterTitle={`Chapter ${index + 1}: ${chapter.title}`}
                    topicsCount={chapter.topics.length}
                    questionsCount={chapter.topics.reduce(
                      (acc, topic) => acc + topic.questions.length,
                      0,
                    )}
                    onClick={() => {
                      navigate(
                        `/app/course/${courseId}/chapter/${chapter.id}/edit`,
                      );
                    }}
                  />
                ))
              : null}
            <div className="add-new-chapter" onClick={handleAddNewChapter}>
              <FontAwesomeIcon className="m-2" icon={faPlus} />
              Add a {courseInfo.chapters.length > 0 ? "new" : ""} chapter
            </div>
          </div>
          <div className="d-flex form-container justify-content-between section">
            <Button
              variant="link"
              // onClick={() => {
              //   dispatch(clearNewCourse());
              // }}
              as={Link}
              to="/app/dashboard"
            >
              Back
            </Button>
            <Button
              variant="primary"
              onClick={handleSubmit}
              disabled={isUpdateCourseLoading}
            >
              Save
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default EditCourse;
