import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import "./Signin.css";
import { useLazyFetchUserQuery, useLoginMutation } from "../redux/api";
import { useDispatch } from "react-redux";
import { loginSuccess, setUser } from "../redux/auth";
import { Alert } from "react-bootstrap";
import Logo from "../shared/components/Logo";

// Yup validation schema
const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
});

function Signin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [login, { isLoading, isError, error }] = useLoginMutation();
  const [fetchUser] = useLazyFetchUserQuery();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        // Step 1: Login and get the tokens
        const loginResponse = await login({
          email: values.email,
          password: values.password,
        }).unwrap();
        const { access, refresh } = loginResponse;

        // Step 2: Dispatch loginSuccess to store tokens in Redux and localStorage
        dispatch(loginSuccess({ accessToken: access, refreshToken: refresh }));

        // Step 3: Fetch the user profile
        const user = await fetchUser().unwrap();

        // Dispatch setUser to store user profile in Redux
        dispatch(setUser(user));

        // Redirect to dashboard or another route after successful login
        navigate("/app/dashboard");
      } catch (error) {
        console.error("Login or fetch user failed", error);
      }
      setSubmitting(false);
    },
  });

  return (
    <Container
      fluid
      className="d-flex flex-column justify-content-center align-items-center signin-form-container"
    >
      <Row className="w-100">
        <Col
          sm={12}
          className="d-flex justify-content-center flex-column align-items-center"
        >
          <div className="w-100 d-flex justify-content-center flex-column align-items-center">
            <Logo />
          </div>
          <Card className="form-container mt-4">
            <Form className="signin-form" onSubmit={formik.handleSubmit}>
              <h1>Sign in</h1>
              <div className="w-100 d-flex justify-content-center flex-column align-items-center">
                {/* <Logo /> */}
              </div>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="john.doe@example.com"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={!!formik.errors.email && formik.touched.email}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.email}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={
                    !!formik.errors.password && formik.touched.password
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.password}
                </Form.Control.Feedback>
              </Form.Group>
              {isError && (
                <Alert variant="danger">
                  {`Error: ${error?.data?.detail}` ||
                    "An error occured please try again."}
                </Alert>
              )}
              <div>
                <Button
                  type="submit"
                  disabled={formik.isSubmitting || isLoading}
                >
                  Sign in
                </Button>
              </div>
              <div>
                Don't have an account? <Link to="/signup">Sign up</Link>
              </div>
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Signin;
