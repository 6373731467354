import { Breadcrumb, Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import TopicForm from "../TopicForm"; // Renamed from VideoForm
import "../Topic.css";
import { useCreateTopicMutation } from "../../../redux/api";
import { clearNewTopic, setTopicInfo } from "../../../redux/newTopic";
import { useState } from "react";
import toast from "react-hot-toast";

const NewTopic = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [createTopic, { isLoading }] = useCreateTopicMutation();
  const { topicInfo } = useSelector((state) => state.newTopic);
  const [videoFile, setVideoFile] = useState();
  let { courseId, chapterId } = useParams();

  const handleSubmitTopic = async () => {
    try {
      const formData = new FormData();
      formData.append("chapter", chapterId);
      formData.append("title", topicInfo.title);
      formData.append("video_file", videoFile);
      const createdTopic = await createTopic(formData).unwrap();
      dispatch(clearNewTopic());
      navigate(
        `/app/course/${courseId}/chapter/${chapterId}/topic/${createdTopic.id}/edit`,
      );
      toast.success("Topic added successfully!");
    } catch (err) {
      console.error("Failed to create topic:", err);
      toast.error("Something went wrong.");
    }
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => navigate(`/app/dashboard`)}>
          Dashboard
        </Breadcrumb.Item>
        <Breadcrumb.Item onClick={() => navigate(`/app/newcourse/`)}>
          Course
        </Breadcrumb.Item>
        <Breadcrumb.Item
          onClick={() =>
            navigate(`/app/course/${courseId}/chapter/${chapterId}/edit`)
          }
        >
          Chapter
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Topic</Breadcrumb.Item>
      </Breadcrumb>

      <Row>
        <Col className="d-flex flex-column justify-content-center align-items-center header">
          <h1 className="form-heading">New Topic</h1>
          <p className="text-muted">
            Its time to add your video and quiz questions.
          </p>
        </Col>
      </Row>
      <Row className="w-100 mb-5">
        <Col
          sm={12}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          <TopicForm
            initialValues={{
              title: topicInfo?.title ?? "",
              videoFile: null,
            }}
            onValuesChange={(updatedValues) => {
              dispatch(setTopicInfo({ title: updatedValues.title }));
              setVideoFile(updatedValues.videoFile);
            }}
          />
          <div className="form-container d-flex justify-content-between mt-3">
            <Button
              variant="link"
              as={Link}
              // onClick={() => {
              //   dispatch(clearNewTopic());
              // }}
              to={`/app/course/${courseId}/chapter/${chapterId}/edit`}
            >
              Back
            </Button>
            <div>
              <Button
                type="button"
                onClick={handleSubmitTopic}
                disabled={isLoading}
              >
                Save Topic
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default NewTopic;
