import { useFormik } from "formik";
import Form from "react-bootstrap/Form";
import * as Yup from "yup";

const ChapterInfoForm = ({ initialValues, onValuesChange }) => {
  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      title: Yup.string().required("Chapter title is required"),
      description: Yup.string().required("Chapter description is required"),
    }),
  });

  const handleBlur = (e) => {
    formik.handleBlur(e);
    onValuesChange(formik.values);
  };

  return (
    <Form onBlur={handleBlur} className="form-container section">
      <h4>Chapter Overview</h4>
      <Form.Group>
        <Form.Label>Chapter title</Form.Label>
        <Form.Control
          type="text"
          name="title"
          placeholder="Introduction to yoga from home"
          value={formik.values.title}
          onChange={formik.handleChange}
          isInvalid={!!formik.errors.title && formik.touched.title}
        />
        <Form.Control.Feedback type="invalid">
          {formik.errors.title}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Description</Form.Label>
        <Form.Control
          as="textarea"
          rows={5}
          name="description"
          placeholder="In this chapter we will learn..."
          value={formik.values.description}
          onChange={formik.handleChange}
          isInvalid={!!formik.errors.description && formik.touched.description}
        />
        <Form.Control.Feedback type="invalid">
          {formik.errors.description}
        </Form.Control.Feedback>
      </Form.Group>
    </Form>
  );
};

export default ChapterInfoForm;
