import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";

const CourseForm = ({ initialValues, onValuesChange }) => {
  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      title: Yup.string().required("Course title is required"),
      description: Yup.string().required("Course description is required"),
    }),
  });

  return (
    <Form
      className="form-container section"
      onBlur={(e) => {
        formik.handleBlur(e);
        if (formik.isValid) {
          onValuesChange(formik.values);
        }
      }}
    >
      <h4>Overview</h4>
      <Form.Group>
        <Form.Label>Course title</Form.Label>
        <Form.Control
          type="text"
          name="title"
          placeholder="Intro to yoga from home"
          value={formik.values.title}
          onChange={formik.handleChange}
          isInvalid={!!formik.errors.title && formik.touched.title}
        />
        <Form.Control.Feedback type="invalid">
          {formik.errors.title}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <Form.Label>Description</Form.Label>
        <Form.Control
          as="textarea"
          rows={5}
          name="description"
          placeholder="Describe what your course is about"
          value={formik.values.description}
          onChange={formik.handleChange}
          isInvalid={!!formik.errors.description && formik.touched.description}
        />
        <Form.Control.Feedback type="invalid">
          {formik.errors.description}
        </Form.Control.Feedback>
      </Form.Group>
    </Form>
  );
};

export default CourseForm;
