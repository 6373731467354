import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "./api";

const initialState = {
  courseInfo: {
    title: "",
    description: "",
    chapters: [],
  },
};

const newCourseSlice = createSlice({
  name: "newCourse",
  initialState,
  reducers: {
    // Setting course info (title and description)
    setCourseInfo: (state, action) => {
      state.courseInfo = { ...state.courseInfo, ...action.payload };
    },

    // Clear the entire new course state
    clearNewCourse: () => {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      apiSlice.endpoints.getCourse.matchFulfilled,
      (state, action) => {
        state.courseInfo = action.payload;
      },
    );
  },
});

export const { setCourseInfo, clearNewCourse } = newCourseSlice.actions;

export default newCourseSlice.reducer;
