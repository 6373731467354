import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Alert, Card } from "react-bootstrap";

import "./Signup.css";
import { useSignUpMutation } from "../redux/api";
import Logo from "../shared/components/Logo";

// Yup validation schema
const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm your password"),
});

function SignUp() {
  const navigate = useNavigate();
  const [signUp, { isLoading, isError, error }] = useSignUpMutation();

  const formik = useFormik({
    initialValues: {
      firstname: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await signUp({
          firstname: values.firstname,
          email: values.email,
          password: values.password,
          re_password: values.confirmPassword,
        }).unwrap();

        // Redirect to login page on successful sign up
        navigate("/signin");
      } catch (err) {
        console.error("Sign-up failed:", err);
      }
      setSubmitting(false);
    },
  });

  return (
    <Container
      fluid
      className="d-flex flex-column justify-content-center align-items-center signup-form-container"
    >
      <Row className="w-100">
        <Col
          sm={12}
          className="d-flex justify-content-center flex-column align-items-center"
        >
          <div className="w-100 d-flex justify-content-center">
            <Logo />
          </div>
          <Card className="form-container mt-4">
            <Form className="signup-form" onSubmit={formik.handleSubmit}>
              <h1>Create an account</h1>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  name="firstname"
                  placeholder="John Doe"
                  value={formik.values.firstname}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={!!formik.errors.name && formik.touched.name}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.email}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="john.doe@example.com"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={!!formik.errors.email && formik.touched.email}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.email}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={
                    !!formik.errors.password && formik.touched.password
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.password}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  name="confirmPassword"
                  placeholder="Re-type your password"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={
                    !!formik.errors.confirmPassword &&
                    formik.touched.confirmPassword
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.confirmPassword}
                </Form.Control.Feedback>
              </Form.Group>
              {isError && (
                <Alert variant="danger">
                  {Object.entries(error?.data ?? {}).map(([k, v]) => {
                    return `Error: ${v}`;
                  })}
                </Alert>
              )}
              <div>
                <Button
                  type="submit"
                  disabled={formik.isSubmitting || isLoading}
                >
                  Sign up
                </Button>
              </div>
              <div>
                Already have an account <Link to="/signin">Sign in</Link>
              </div>
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default SignUp;
