// apiSlice.js

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Mutex } from "async-mutex";
import { loginSuccess, logout } from "./auth";

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl: "http://localhost:8000/api",
  prepareHeaders: (headers) => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  // Wait for any pending mutex operation to finish
  await mutex.waitForUnlock();

  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      try {
        const refreshResult = await baseQuery(
          {
            url: "/auth/jwt/refresh/",
            method: "POST",
            body: { refresh: localStorage.getItem("refreshToken") },
          },
          api,
          extraOptions,
        );

        if (refreshResult.data) {
          // Store the new access token
          const newAccessToken = refreshResult.data.access;
          localStorage.setItem("accessToken", newAccessToken);

          // Dispatch the loginSuccess action to update the access token in the Redux state
          api.dispatch(
            loginSuccess({
              accessToken: newAccessToken,
              refreshToken: localStorage.getItem("refreshToken"),
            }),
          );

          // Retry the original query with the new access token
          result = await baseQuery(args, api, extraOptions);
        } else {
          // If refresh fails, log out the user
          api.dispatch(logout());
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
        }
      } finally {
        // Release the mutex lock
        release();
      }
    } else {
      // Wait for mutex to be unlocked and retry the original request
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }

  return result;
};

// Define your API service
export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Courses", "Course", "Chapters", "Chapter", "Topics", "Topic"],
  endpoints: (builder) => ({
    askLLMAfterClass: builder.mutation({
      query: (data) => ({
        url: "/ask-llm-after-class/",
        method: "POST",
        body: data,
      }),
    }),
    askLLMDuringVideo: builder.mutation({
      query: (data) => ({
        url: "/ask-llm-during-video/",
        method: "POST",
        body: data,
      }),
    }),

    login: builder.mutation({
      query: (credentials) => ({
        url: "/auth/jwt/create/",
        method: "POST",
        body: credentials,
      }),
    }),
    fetchUser: builder.query({
      query: () => ({
        url: "/users/me/",
        method: "GET",
      }),
    }),
    signUp: builder.mutation({
      query: (userData) => ({
        url: "/users/",
        method: "POST",
        body: userData,
      }),
    }),

    createCourse: builder.mutation({
      query: (courseData) => ({
        url: "/courses/",
        method: "POST",
        body: courseData,
      }),
      invalidatesTags: ["Courses", "Course"],
    }),
    updateCourse: builder.mutation({
      query: ({ courseId, courseData }) => ({
        url: `/courses/${courseId}/`,
        method: "PATCH",
        body: courseData,
      }),
      invalidatesTags: ["Courses", "Course"],
    }),
    getCourses: builder.query({
      query: () => "/courses/", // Adjust the endpoint as per your API
      providesTags: ["Courses"],
    }),
    getCourse: builder.query({
      query: (courseId) => `/courses/${courseId}/`, // Adjust the endpoint as per your API
      providesTags: ["Course"],
      transformResponse: (response) => ({
        ...response,
        creator: {
          ...response.creator,
          name: response.creator.first_name + " " + response.creator.last_name,
        },
      }),
    }),

    createChapter: builder.mutation({
      query: (chapterData) => ({
        url: "/chapters/",
        method: "POST",
        body: chapterData,
      }),
      invalidatesTags: ["Chapters", "Chapter", "Course"],
    }),
    updateChapter: builder.mutation({
      query: (chapterData) => ({
        url: `/chapters/${chapterData.id}/`,
        method: "PATCH",
        body: chapterData,
      }),
      invalidatesTags: ["Chapters", "Chapter", "Course"],
    }),
    getChapters: builder.query({
      query: (courseId) => `/chapters/?course=${courseId}/`,
      providesTags: ["Chapters"],
    }),
    getChapter: builder.query({
      query: (chapterId) => `/chapters/${chapterId}/`,
      providesTags: ["Chapter"],
    }),

    createTopic: builder.mutation({
      query: (topicData) => ({
        url: "/topics/",
        method: "POST",
        body: topicData,
      }),
      invalidatesTags: ["Topics", "Topic", "Chapter"],
    }),
    updateTopic: builder.mutation({
      query: ({ topicId, topicData }) => ({
        url: `/topics/${topicId}/`,
        method: "PATCH",
        body: topicData,
      }),
      invalidatesTags: ["Topics", "Topic", "Chapter"],
    }),
    getTopics: builder.query({
      query: (chapterId) => `/topics/?chapter=${chapterId}/`,
      providesTags: ["Topics"],
    }),
    getTopic: builder.query({
      query: (topicId) => `/topics/${topicId}/`,
      providesTags: ["Topic"],
    }),
  }),
});

export const {
  useAskLLMAfterClassMutation,
  useAskLLMDuringVideoMutation,

  useLoginMutation, // Export the login hook
  useLazyFetchUserQuery,
  useSignUpMutation,

  useCreateCourseMutation,
  useUpdateCourseMutation,
  useGetCoursesQuery,
  useGetCourseQuery,

  useCreateChapterMutation,
  useUpdateChapterMutation,
  useGetChapterQuery,

  useCreateTopicMutation,
  useUpdateTopicMutation,
  useGetTopicQuery,
} = apiSlice;
