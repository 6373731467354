import { Row, Col, Button, Spinner, Alert } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Course from "./CourseCard";
import Tile from "./tile";
import { useGetCoursesQuery } from "../../redux/api"; // Import the RTK Query hook

import "./Dashboard.css";

function Dashboard() {
  const navigate = useNavigate();
  const {
    data: courses = [],
    isLoading,
    isError,
    error,
  } = useGetCoursesQuery();

  // Render a loading spinner while data is being fetched
  if (isLoading) {
    return (
      <Row className="d-flex justify-content-center">
        <Spinner animation="border" />
      </Row>
    );
  }

  // Render an error message if the request fails
  if (isError) {
    return (
      <Row className="d-flex justify-content-center">
        <Alert variant="danger">Error fetching courses: {error.message}</Alert>
      </Row>
    );
  }

  return (
    <>
      <Row>
        <Col lg={6}>
          <h1>Dashboard</h1>
        </Col>
        <Col lg={6} className="d-flex justify-content-end align-items-center">
          {courses.length > 0 && (
            <Button as={Link} variant="primary" to="/app/course/add">
              Create New Course
            </Button>
          )}
        </Col>
        <Col sm={12}>
          <Row>
            <p className="text-muted">
              Hey, here are some stats about your courses
            </p>
          </Row>
          <Row>
            <Col lg={2} md={3} xs={6}>
              <Tile number={courses.length} caption="Courses" />
            </Col>
            <Col lg={2} md={3} xs={6}>
              <Tile number={0} caption="Enrollments" />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="section">
        <Col>
          <h2>Courses</h2>
        </Col>
      </Row>
      <Row className="g-2 d-flex align-items-stretch">
        {courses.length > 0 ? (
          courses.map((course) => (
            <Col key={course.id} lg={3} md={4} sm={12}>
              <Course
                title={course.title}
                chapters={course.chapters.length}
                students={course.students_count || 0}
                onClick={() => navigate(`/course/${course.id}`)}
              />
            </Col>
          ))
        ) : (
          <Col
            sm={12}
            className="d-flex justify-content-center flex-column align-items-center"
          >
            <h5 className="empty-dashboard-prompt mt-5 text-muted">
              You haven't started any courses yet. Let's create one now.
            </h5>
            <Button
              as={Link}
              className="mt-3"
              variant="dark"
              to="/app/course/add"
            >
              Create a new course
            </Button>
            <img
              className="empty-dashboard-image"
              src="/dashboard-empty.png"
              alt="empty-dashboard-illustration"
            />
          </Col>
        )}
      </Row>
    </>
  );
}

export default Dashboard;
